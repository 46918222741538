body {
  font-family: "Alef", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.App {
  text-align: center;
}

header {
  background-size: cover;
  background-position: center;
}

header h1 {
  font-size: 5rem;
}

img {
  border-style: solid;
  border-width: 5px;
}

.navbar-icon {
  width: 50px;
  /* Adjust size as needed */
  height: 50px;
  /* Adjust size as needed */
  object-fit: cover;
  /* Ensures the image scales properly */
  border: 2px solid black;
  /* Adds a black border around the image */
  background: transparent;
  /* Ensures the background is transparent */
  display: block;
  /* Removes any extra spacing around the image */
  padding: 1px;
  /* Optional: Adds some space between the image and the border */
  box-sizing: border-box;
  /* Ensures padding and border are included in the element's total width and height */
}



.height-50 {
  height: 50vh;
}

@media only screen and (max-width: 768px) {
  header h1 {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 576px) {
  header h1 {
    font-size: 3rem;
  }
}
