@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body, h1, h2, h3, p, a, code {
font-family: "Alef", sans-serif;
  font-weight: 400;
  font-style: normal;;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}