.header-container {
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(../../utils/images/home-header-img.jpg);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 2rem 0;
    position: relative;
}

.offset-wrapper {
    position: relative;
    right: 15%;
    width: 70%;
}

.text-container {
    padding-right: 15px;
}

.welcome-text {
    font-size: 2.5rem;
    font-weight: bold;
}

.restaurant-name {
    font-size: 5rem;
    font-weight: bold;
    color: #ffc107;
}

.description-text {
    font-size: 1.4rem;
    line-height: 1.6;
}

.button-container {
    width: 100%;
}

.scroll-down-arrow {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    animation: bounce 2s infinite;
}



@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0) translateX(-50%);
    }

    40% {
        transform: translateY(-30px) translateX(-50%);
    }

    60% {
        transform: translateY(-15px) translateX(-50%);
    }
}

@media (max-width: 992px) {
    .offset-wrapper {
        left: 15%;
        width: 85%;
    }
}

@media (max-width: 768px) {
    .offset-wrapper {
        left: 5%;
        width: 95%;
    }

    .welcome-text {
        font-size: 2rem;
    }

    .restaurant-name {
        font-size: 4rem;
    }

    .description-text {
        font-size: 1.2rem;
    }
}