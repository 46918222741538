/* #form .form-control {
    background-color: rgb(33,37,41);
    color: #dee2e6;
} */

#results #modal {
    display: flex;
    align-items: center;
}

#results #modal .btn-close {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.form-control.invalid-input {
    border: 1px solid #ea868f;
    box-shadow: 0 0 0 0.25rem rgba(220,53,69,.25);
}